﻿import gsap from 'gsap';

import {
    ScrollTrigger
} from 'gsap/ScrollTrigger';

import {
    ScrollSmoother
} from 'gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);



var tl = gsap.timeline();
tl.to(".line h2", {
    y: 0,
    stagger: 0.2

})

tl.to(".external__list__item", {
    opacity: 1,
    stagger: 0.4

});





gsap.to(".hero__image", {
    scrollTrigger: {
        trigger: ".hero__image",
        start: "top center",
        scrub: true,
    },
    scale: 1.17,
    duration: 0.4

})
gsap.from(".line-decoration.one", {
    scrollTrigger: {
        trigger: ".line-decoration.one",
        start: "50% 80%",
    },
    width: 0,

});

gsap.from(".line-decoration.two", {
    scrollTrigger: {
        trigger: ".line-decoration.two",
        start: "50% 80%",
    },
    width: 0,

});

gsap.from(".line-decoration.three", {
    scrollTrigger: {
        trigger: ".line-decoration.three",
        start: "50% 80%",
    },
    width: 0,

});


///smooth scroll

//let smoother = ScrollSmoother.create({
//    wrapper: '#smooth-wrapper',
//    wrapper: '#smooth-content',
//    smoooth: 2,
//    smoothTouch: 0.1
//})





///fogot password flip

let login = gsap.timeline();


login.set(".formBack", {
    duration: 1.5,
    visibility: "visible",
    display: "block"
});

login.from(".feature__image", {
    scale: 1.1,
    duration: 30,
    ease: "power4.out",
}, "<");

login.fromTo(".login__form", {
    opacity: 0
}, { opacity: 1, delay: 1, y: 0, duration: 1 }, "<");
