﻿import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';


// init Swiper:
document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper('.swiper', {
        // configure Swiper to use modules
        modules: [Navigation, Pagination],
        //cssMode: true,
        direction: 'horizontal',
        loop: true,
        slidesPerView: 'auto',
        //autoplay: {
        //    delay: 4000,
        //    disableOnInteraction: false,
        //},
        grabCursor: true,
        //navigation: true,
        navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
        },
        pagination: {
            el: ".swiper-pagination",
            type: 'bullets',
            clickable: true,
        },
        mousewheel: true,
        keyboard: true,
    });
});