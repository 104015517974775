﻿import * as ExtrataUtils  from './utils/extrata.fetch.js';


if (document.readyState === 'loading') {
    // modern browsers support DOMContentLoaded event
    document.addEventListener('DOMContentLoaded', ExtrataLoaded);
} else {
    // fallback for older browsers that use readystatechange event
    document.onreadystatechange = function () {
        if (document.readyState === 'interactive') {
            ExtrataLoaded();
        }
    };
}

function ExtrataLoaded() {
    console.log("Extrata is ready");
    //ExtrataUtils.neiltest();
}